import React from 'react'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import { locale } from '../../../src/common/i18n'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingQuadCa = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
          Els Quad i vehicles ATV estan principalment dissenyats per a
          competicions esportives i recórrer terrenys sorrencs o irregulars. Per
          tant, es pot dir que no estan concebuts com la resta dels vehicles com
          a mode de transport per carreteres obertes al trànsit. La DGT els
          categoritza com Vehicles Especials.
        </p>
        <p className={styles.main_description}>
          Al món del pneumàtic existeix cada vegada una demanda més alta de
          <strong> Pneumàtics per Quad i Vehicles ATV</strong>, aquests es
          caracteritzen per tenir un <strong>marcat estil Off Road</strong>.
        </p>
        <section className={styles.section}>
          <h2>
            Quins punts són important a l'hora d'escollir pneumàtics per al meu
            Quad?
          </h2>
          <p>
            Hi ha un <strong>aspecte a tenir en compte d'importància</strong>{' '}
            per escollir els pneumàtics del teu Quad. Aquests es la{' '}
            <strong>duresa de la goma</strong>. Com més tova sigui la goma del
            pneumàtic del teu Quad més adherència t'oferirà en terrenys humits.
            En el cas de terreny sec, sorra o sobre zones pedregoses, la millor
            opció és triar un pneumàtic amb un compost més dur.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>Consells per a l'elecció de pneumàtics per al teu Quad.</h2>
          <p>
            Per no equivocar-te en l'elecció de les rodes pel Quad t'aconsellem
            que prenguis nota del següent:
          </p>
          <ul>
            <li>
              <strong>Llegir les indicacions del pneumàtic </strong>del teu
              Quad. Les trobaràs al flanc dels seus pneumàtics actuals.
            </li>
            <li>
              Fixa't bé en<strong> els índexs de càrrega i velocitat</strong>.
              Els Quad són vehicles que no estan pesats per córrer, el seu punt
              fort es la força. Per tant fixa't de no escollir uns pneumàtics
              amb una velocitat per sota de la marcada en la fitxa tècnica del
              Quad.
            </li>
            <li>
              Pensa bé quin ús li dones al Quad habitualment per escollir el
              tipus de goma més adequat.
            </li>
            <li>
              Quan facis un canvi de pneumàtics, en el cas dels Quad, pot
              donar-se que només hagis de substituir-ne un pneumàtic. Això es
              deu a què fora d'asfalt la possibilitat de punxar una roda
              s’incrementa. És recomanable que el pneumàtic nou que adquireixis
              compti amb les mateixes especificacions que l'anterior, sent de la
              mateixa marca i amb el mateix perfil.
            </li>
            <li>
              Com en el cas dels turismes, els Quad disposen de 4 rodes i, per
              tant, requereixen també un <strong>procés d'equilibrat</strong>. A
              Rodi ens encarregarem de realitzar-te aquest servei.
            </li>
            <li>
              Manté sempre els pneumàtics del teu Quad dins dels nivells de
              pressió marcats pel fabricant. Ajudaràs a que la roda s'adapti
              millor als terrenys dins del què ha de treballar.
            </li>
          </ul>
          <p>
            Recorda que{' '}
            <strong>a Rodi estem sempre disposats per ajudar-te </strong>amb el
            teu Quad. <strong>Demana una Cita Prèvia </strong>i un dels nostres
            tècnics especialistes t'assessorarà en l'elecció de les teves rodes
            noves.
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingQuadEs = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
          Los Quad y vehículos ATV están principalmente diseñados para
          competiciones deportivas y recorrer terrenos arenosos o irregulares.
          Por tanto, se puede decir que no están concebidos como el resto de los
          vehículos como modo de transporte por carreteras abiertas al tráfico.
          La DGT los categoriza como Vehículos Especiales.
        </p>
        <p className={styles.main_description}>
          En el mundo del neumático existe cada vez una demanda más alta de
          <strong> Neumáticos para Quad y Vehículos ATV</strong>, estos se
          caracterizan por tener un <strong>marcado estilo Off Road</strong>.
        </p>
        <section className={styles.section}>
          <h2>
            ¿Qué puntos son importante a la hora de escoger neumáticos para mi
            Quad?
          </h2>
          <p>
            Existe un aspecto a tener en cuenta de importancia para escoger los
            <strong> neumáticos de tu Quad</strong>. Esto es la{' '}
            <strong> dureza de la goma</strong>. Cuanto más blanda sea la goma
            del neumático de tu Quad más agarre te ofrecerá en terrenos húmedos.
            En el caso de terreno seco, arena o sobre zonas pedregosas, la mejor
            opción es elegir un neumático con un compuesto más duro.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>Consejos para la elección de neumáticos para tu Quad. </h2>
          <p>
            Para no equivocarte en la elección de las ruedas para tu Quad te
            aconsejamos que tomes nota de lo siguiente:
          </p>
          <ul>
            <li>
              <strong>Leer las indicaciones del neumático</strong> de tu Quad.
              Las encontrarás en el flanco de sus neumáticos actuales.
            </li>
            <li>
              Fíjate bien en <strong>los índices de carga y velocidad</strong>.
              Los Quad son vehículos que no están pensados para correr, su punto
              fuerte es la fuerza. Por tanto fíjate de no escoger unos
              neumáticos con una velocidad por debajo de la marcada en la ficha
              técnica del Quad.
            </li>
            <li>
              Piensa bien qué uso le das al Quad habitualmente para escoger el
              tipo de goma más adecuada.
            </li>
            <li>
              Cuando realices un cambio de neumáticos, en el caso de los Quad,
              puede darse que sólo debas sustituir uno neumático. Esto se debe a
              que fuera de asfalto la posibilidad de pinchar una rueda se
              incrementa. Es recomendable que el neumático nuevo que adquieras
              cuente con las mismas especificaciones que el anterior, siendo de
              la misma marca y con el mismo perfil.
            </li>
            <li>
              Como en el caso de los turismos, los Quad disponen de 4 ruedas y,
              por tanto, requieren también un{' '}
              <strong>proceso de equilibrado</strong>. En Rodi nos encargaremos
              de realizarte este servicio.
            </li>
            <li>
              Mantén siempre los neumáticos de tu Quad en los niveles de presión
              marcados por el fabricante. Ayudarás a que la rueda se adapta
              mejor a los terrenos en los que debe trabajar.
            </li>
          </ul>
          <p>
            Recuerda que{' '}
            <strong>
              en Rodi estamos siempre dispuestos para ayudarte con tu Quad
            </strong>
            . Solicita una Cita Previa y uno de nuestros técnicos especialistas
            te asesorará en la elección de tus ruedas nuevas.
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingQuad = ({ ...props }: Props) =>
  locale === 'es' ? <LandingQuadEs {...props} /> : <LandingQuadCa {...props} />

export default LandingQuad
